import React from "react";
import { IntlProvider } from "react-intl";

export default ({
  element,
  props: {
    pageContext: { langKey, messages }
  }
}) => (
  <IntlProvider locale={langKey} messages={messages}>
    {element}
  </IntlProvider>
);
